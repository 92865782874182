import { WalletServices } from 'types'
import API, { WALLET, headers } from 'api'
import { errors, api } from 'services/index'
import i18n from 'lang'

const responseToJson = async (response: Response) => {
  try {
    return await response.json()
  } catch {
    return {}
  }
}

const response = async (response: Response, key: string = null) => {
  const res = await responseToJson(response)
  if (key) {
    return {
      ...res,
      status: response.status,
      response: errors.notify(key[response.status], response.status),
    }
  }
  return { ...res, status: response.status, response: null }
}

const wallet: WalletServices = {
  collection: async (customToken) => {
    const promise = await fetch(`${API(WALLET.DEFAULT)}`, {
      method: 'GET',
      headers: headers('default', true, customToken),
    })
    return response(promise, api.wallet.collection)
  },
  once: async ({ id }) => {
    const promise = await fetch(`${API(WALLET.DEFAULT)}/${id}`, {
      method: 'GET',
      headers: headers(),
    })
    return response(promise, api.wallet.once)
  },
  create: async (body, customToken: string) => {
    const promise = await fetch(`${API(WALLET.DEFAULT)}`, {
      method: 'POST',
      body,
      headers: headers('file', true, customToken),
    })
    return response(promise, api.wallet.create)
  },
  update: async ({ id, customToken }, body) => {
    const promise = await fetch(`${API(WALLET.DEFAULT)}/${id}`, {
      method: 'POST',
      body,
      headers: headers('file', true, customToken),
    })
    return response(promise, api.wallet.update)
  },
  remove: async ({ id }) => {
    const promise = await fetch(`${API(WALLET.DEFAULT)}/${id}`, {
      method: 'DELETE',
      headers: headers(),
    })
    return response(promise, api.wallet.remove)
  },
  registration: async (body) => {
    const promise = await fetch(API(WALLET.REGISTRATION), {
      method: 'POST',
      body: JSON.stringify(body),
      headers: headers('default', false),
    })
    return response(promise, api.wallet.remove)
  },
  deleteRegistration: async ({ deviceIdentifier, companyId, customerId }) => {
    const lang = i18n.language === 'fr' ? 'fr-FR' : 'en-EN'

    const promise = await fetch(`${API(WALLET.REGISTRATION)}/${deviceIdentifier}`, {
      method: 'DELETE',
      headers: {
        'content-Type': 'application/json; charset=UTF-8',
        'Accept-Language': lang,
        'X-COMPANY-ID': companyId,
        'X-CUSTOMER-ID': customerId,
      },
    })
    return response(promise, api.wallet.remove)
  },
}

export default wallet
