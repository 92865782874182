import React, {
  useMemo,
  ReactElement,
  SetStateAction,
  Dispatch,
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react'
import { Link } from 'react-router-dom'
import { useGlobalFilter, useSortBy, useTable, usePagination } from 'react-table'
import { useQuery } from 'react-query'
import { useTranslation } from 'react-i18next'
import { Switch } from 'antd'
import moment from 'moment'

import { NotificationCollectionResponse } from 'types/api'
import { routes } from 'router'
import { FiCircle, FiEdit, FiDownload } from 'react-icons/fi'
import { AiOutlineEye } from 'react-icons/ai'
import { FaRegCopy } from 'react-icons/fa'
import { RiDeleteBin6Line } from 'react-icons/ri'
import { ReactTable } from 'components'
import { TableSkeleton } from 'components/Skeletons'
import PreviewNotification from './NotificationPreview/NotificationPreview'
import RemoveNotification from 'components/Notification/RemoveNotification'
import { TableRow, TabColumns, SortIcon, DownloadCustomers } from './styles'
import { CellButton } from 'components/Table/styles'
import { notification as notificationService } from 'services'
import * as GlobalStyle from 'styles/globalStyle'
import { NotificationContext } from 'providers'

type Props = {
  setNotificationCount: Dispatch<SetStateAction<number>>
  searchingWord: string
}

type HandleDisableEnableNotification = {
  checked: boolean
  id: string
}

type Order = {
  type: string
  value: string
}

const NotificationTable = ({ setNotificationCount, searchingWord }: Props): ReactElement => {
  const { t } = useTranslation()
  const { notify } = useContext(NotificationContext)
  const [isLoad, setIsLoad] = useState<boolean>(true)
  const [notifications, setNotifications] = useState<NotificationCollectionResponse>()
  const [notificationPreviewId, setNotificationPreviewId] = useState<string>('')
  const [removeNotificationId, setRemoveNotificationId] = useState<string>('')
  const [targetCustomerList, setTargetCustomerList] = useState<string>('')

  const downloadCustomerFile = useRef(null)

  const [order, setOrder] = useState<Order>({
    type: '',
    value: '',
  })

  const parseDate = (date) => {
    const newDate = new Date(date)
    const parseDate = `${('0' + newDate.getDate().toString()).slice(-2)}/${(
      '0' + (newDate.getMonth() + 1).toString()
    ).slice(-2)}/${newDate.getFullYear()}`
    const parseTime = moment(date).format('HH:mm')
    return `${parseDate} ${parseTime}`
  }

  const notificationIsNotSent = (cell): boolean => new Date(cell.row?.original?.sentAt) > new Date()

  const handleOrder = ({ orderType }) => {
    setOrder({
      type: `order[${orderType}]`,
      value: order.value?.includes('asc') ? 'desc' : 'asc',
    })
  }

  const handleDisableEnableNotification = ({ checked, id }: HandleDisableEnableNotification) => {
    if (notifications?.['hydra:member']) {
      const notifiyResponse = ({ response }) => {
        notify({
          type: response?.type,
          title: t(response?.message),
          message: '',
        })
        const notificationsList = [...notifications?.['hydra:member']]
        notificationsList?.map((notification) => {
          if (notification['@id']?.split('notifications/')?.[1] === id) {
            notification.enabled = checked
          }
        })
        setNotifications({ ...notifications, ['hydra:member']: notificationsList })
      }
      const service = checked ? notificationService.enable : notificationService.disable
      service({ id }).then((notifResponse) => {
        notifiyResponse(notifResponse)
      })
    }
  }

  const handleFindTargetCustomer = ({ id }) => {
    notificationService.customers({ id }).then((res) => {
      if (res.status === 202) {
        setTargetCustomerList(res.csv)
        notify({
          type: 'success',
          title: t(`notification.exportTargetingFile.202.title`),
          message: t(`notification.exportTargetingFile.202.message`),
        })
      } else {
        notify({
          type: 'error',
          title: t(`notification.exportTargetingFile.429.title`),
          message: t(`notification.exportTargetingFile.429.message`),
        })
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        id: 'col1',
        Header: (
          <GlobalStyle.Row>
            <p>{t('notification.table.0')}</p>
            <SortIcon marginTop={7} onClick={() => handleOrder({ orderType: 'name' })} />
          </GlobalStyle.Row>
        ),
        accessor: 'name',
      },
      {
        id: 'col2',
        Header: (
          <GlobalStyle.RowCenter>
            <p>{t('notification.table.1')}</p>
            <SortIcon onClick={() => handleOrder({ orderType: 'sentAt' })} />
          </GlobalStyle.RowCenter>
        ),
        accessor: 'sentAt',
        type: 'text',
        Cell: ({ cell }: any) => <p>{cell.value ? parseDate(cell.value) : ''}</p>,
      },
      {
        id: 'col3',
        Header: (
          <GlobalStyle.RowCenter>
            <p>{t('notification.table.2')}</p>
            <SortIcon onClick={() => handleOrder({ orderType: 'endAt' })} />
          </GlobalStyle.RowCenter>
        ),
        accessor: 'endAt',
        type: 'text',
        Cell: ({ cell }: any) => <p>{cell.value ? parseDate(cell.value) : ''}</p>,
      },
      {
        id: 'col4',
        Header: t('notification.table.3'),
        type: 'text',
        Cell: ({ cell }: any) => {
          if (notificationIsNotSent(cell)) {
            return (
              <GlobalStyle.RowEnd>
                <GlobalStyle.Text margin="0 5px 0 0">
                  {t(cell.row?.original?.enabled ? 'enabled' : 'disabled')}
                </GlobalStyle.Text>
                <Switch
                  onChange={(checked) =>
                    handleDisableEnableNotification({ checked, id: cell.row?.original?.id })
                  }
                  checked={cell.row?.original?.enabled}
                />
              </GlobalStyle.RowEnd>
            )
          }
          return <></>
        },
      },
      {
        id: 'col5',
        Header: t('notification.table.4'),
        accessor: 'id',
        type: 'button',
        icon: <AiOutlineEye />,
        Cell: ({ cell }: any) => (
          <CellButton onClick={() => setNotificationPreviewId(cell.value)}>
            {cell.column.icon || <FiCircle />}
          </CellButton>
        ),
      },
      {
        id: 'col6',
        Header: t('notification.table.5'),
        type: 'button',
        icon: <FiEdit />,
        Cell: ({ cell }: any) => {
          if (new Date(cell.row?.original?.endAt) > new Date()) {
            return (
              <Link to={`${routes.editNotification.path}?notificationId=${cell.row?.original?.id}`}>
                <CellButton>{cell.column.icon || <FiCircle />}</CellButton>
              </Link>
            )
          }
          return <div />
        },
      },
      {
        id: 'col7',
        Header: t('notification.table.6'),
        accessor: 'id',
        type: 'button',
        icon: <FaRegCopy />,
        Cell: ({ cell }: any) => (
          <Link to={`${routes.duplicateNotification.path}?duplicateNotificationId=${cell.value}`}>
            <CellButton>{cell.column.icon || <FiCircle />}</CellButton>
          </Link>
        ),
      },
      {
        id: 'col8',
        Header: t('notification.table.7'),
        type: 'button',
        icon: <RiDeleteBin6Line />,
        Cell: ({ cell }: any) => {
          if (notificationIsNotSent(cell)) {
            return (
              <CellButton onClick={() => setRemoveNotificationId(cell.row?.original?.id)}>
                {cell.column.icon || <FiCircle />}
              </CellButton>
            )
          }
          return <div />
        },
      },
      {
        id: 'col9',
        Header: t('notification.table.8'),
        accessor: 'customersTargeted',
        type: 'text',
        Cell: (cell) => (
          <p>{cell.value}</p> // TO DO CLIENTS CIBLÉS
        ),
      },
      {
        id: 'col10',
        Header: t('notification.table.9'),
        accessor: 'id',
        type: 'button',
        icon: <FiDownload style={{ color: '#22223a' }} />,
        Cell: ({ cell }: any) => (
          <CellButton onClick={() => handleFindTargetCustomer({ id: cell.value })}>
            {cell.column.icon || <FiCircle />}
          </CellButton>
        ),
      },
    ],
    [t, notifications, order, searchingWord],
  )

  const pageSize = 30

  const tableInstance = useTable(
    {
      columns,
      data: notifications?.['hydra:member'] || [],
      autoResetGlobalFilter: false,
      autoResetSortBy: false,
      manualPagination: true,
      pageCount: Math.ceil(notifications?.['hydra:totalItems'] / pageSize),
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const {
    state: { pageIndex },
  } = tableInstance

  const { data, refetch: refreshNotifications } = useQuery(
    [
      `notifications${order.type}${order.value}${searchingWord}`,
      order,
      searchingWord,
      { pageIndex: pageIndex + 1 },
    ],
    () =>
      notificationService.collection({
        page: pageIndex + 1,
        itemsPerPage: pageSize,
        order,
        searchingWord,
      }),
    {
      staleTime: 10,
    },
  )

  useEffect(() => {
    if (data) {
      setIsLoad(false)
      setNotifications(data)
      setNotificationCount(data?.['hydra:totalItems'])
    }
  }, [data])

  return (
    <TableRow>
      <DownloadCustomers
        filename="participants_ciblés.csv"
        data={targetCustomerList}
        ref={downloadCustomerFile}
      />
      {notificationPreviewId && (
        <PreviewNotification
          parseDate={parseDate}
          notificationPreviewId={notificationPreviewId}
          setNotificationPreviewId={setNotificationPreviewId}
        />
      )}
      {removeNotificationId && (
        <RemoveNotification
          refreshNotifications={refreshNotifications}
          setRemoveNotificationId={setRemoveNotificationId}
          removeNotificationId={removeNotificationId}
        />
      )}
      {isLoad ? (
        <TableSkeleton />
      ) : (
        <TabColumns>
          <ReactTable tableInstance={tableInstance} />
        </TabColumns>
      )}
    </TableRow>
  )
}

export default NotificationTable
