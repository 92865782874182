import React, { ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from 'antd'

import * as GlobalStyle from 'styles/globalStyle'

type Props = {
  isDisplayed: boolean
}

const ModalWarningDelimiter = ({ isDisplayed }: Props): ReactElement => {
  const [isClose, setIsClose] = useState(false)

  const { t } = useTranslation()

  useEffect(() => {
    setIsClose(false)
  }, [isDisplayed])

  return (
    <Modal
      className="center"
      centered
      footer={[
        <GlobalStyle.Button.primary key="submit" onClick={() => setIsClose(true)}>
          {t('understood')}
        </GlobalStyle.Button.primary>,
      ]}
      visible={isDisplayed && isClose === false}
      onOk={() => setIsClose(true)}
      onCancel={() => setIsClose(true)}
    >
      {t('warningDelimiterSelection')}
    </Modal>
  )
}

export default ModalWarningDelimiter
